import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'gatsby-plugin-intl';

import HomeIcon from '@material-ui/icons/HomeOutlined';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { changeLocale } from 'gatsby-plugin-intl';

import {
  AppBar,
  Typography,
  Toolbar,
  MenuItem,
  Menu,
  IconButton,
  Container,
  Grid,
  NoSsr,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { FontController } from './FontController';

import { Link } from 'components/link/Link';
import StarterLogo from 'assets/svg/starter-logo.svg';

import s from './HeaderTop.scss';
import { Social } from '../social/Social';
import { LanguageController } from './LanguageController';

// function ElevationScroll(props: HeaderProps) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 0,
//   });
// }

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 16,
    height: 16,
  },
  spacer: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const HeaderTop = () => {
  const intl = useIntl();
  const classes = useStyles();

  const isSSR = typeof window === 'undefined';
  let $html: HTMLHtmlElement;
  let fontBaseSize = '';
  if(!isSSR){
    $html = document.querySelector('html') || new HTMLHtmlElement();
    fontBaseSize = window.getComputedStyle($html).getPropertyValue('font-size');
  }
  
  return (
    <Container className={s.header} maxWidth={false}>
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs>
          <Link to="/" className={s.header__linkIcon}>
            <HomeIcon fontSize="small" className={classes.icon} />
            {intl.formatMessage({ id: 'home' })}
          </Link>
          <Link to="/help" className={`${s.header__linkIcon} ${s.header__second}`}>
            <HelpIcon fontSize="small" className={classes.icon} />
            {intl.formatMessage({ id: 'help' })}
          </Link>
        </Grid>
        <Grid item xs container justify="flex-end" className={classes.spacer}>
          <Social className="normal" />
          <NoSsr>
            <FontController baseSize={fontBaseSize} />
          </NoSsr>
          <LanguageController />
        </Grid>
      </Grid>
    </Container>
  );
};

HeaderTop.propTypes = {
  siteTitle: PropTypes.string,
};

HeaderTop.defaultProps = {
  siteTitle: ``,
};
