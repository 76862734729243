import React from 'react';
import s from './Footer.scss';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'components/link/Link';
import Typography from '@material-ui/core/Typography';

export const  FooterLinks = ({links} : {links:[]}) => {

    const intl = useIntl();
    return (
        <>
    <div className={`${s.footer}`}>
    <Typography component="div">
        {links.map((link, i) =>(
            <Link key={i}  className={s.link} to={`/${link.name}`}>
              <div className={s.footer__text}>
                {intl.formatMessage({ id: link.name })}
                </div>
            </Link>
          ))}
    </Typography>
    </div>
    </>
    )
}

export const  FooterLinksBottom = ({links} : {links:[]}) => {

    const intl = useIntl();
    return (
        <>
    <div className={`${s.footer}`}>
    <Typography component="div">
        {links.map((link, i) =>(
            <Link key={i}  className={s.linkbottom} to={`/${link.name}`}>
              <div className={s.footer__text}>
              <span className={s.bullet}>&#8226;</span>
                {intl.formatMessage({ id: link.name })}
                </div>
            </Link>
          ))}
    </Typography>
    </div>
    </>
    )
}
