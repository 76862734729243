import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import classes from 'components/feedback/Feedback.scss';


export default function Feedback() {
  const [rating, setRating] = React.useState(2);
  const [reason, setReason] = React.useState('');
  const [showFeedback, setShowFeedback] = React.useState(false);

  const handleChange = (event:any) => {
    setReason(event.target.value);
  };

  const handleToggle = (toggle:boolean) => {
    setShowFeedback(!toggle);
  };

  return (
      <div className={showFeedback ? classes.hide : classes.show}>
        <div className={showFeedback ? classes.button__close : classes.button__open}
             onClick={() => handleToggle(showFeedback)}
        >
          Feedback
        </div>
        <Card className={classes.card}>
          <div className={classes.header}>
            <div className={classes.title}>
              Your Feedback
              <CloseIcon
                className={classes.icon}
                onClick={() => handleToggle(showFeedback)} />
            </div>
            <Divider light={true} component="hr" />
            <Box
              className={classes.rating}
              component="fieldset"
              mb={2}
              pt={1}
              borderColor="transparent"
            >
              <Typography component="legend">What is your opinion on this page?</Typography>
              <Rating
                name="simple-controlled"
                size="large"
                value={rating}
                onChange={(_event, newValue) => {
                  if(newValue != null){
                    setRating(newValue);
                  }
                }}
              />
            </Box>
          </div>
          <CardContent className={classes.content}>
            <form noValidate autoComplete="off">
              <InputLabel htmlFor="reason" className={classes.label}>
                Reason For ? <span className={classes.required}>*</span>
              </InputLabel>
              <FormControl variant="outlined" className={classes.input}>
                <Select native id="reason" value={reason} onChange={handleChange}>
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value={20}>Design</option>
                  <option value={30}>Feature</option>
                  <option value={40}>Ease of</option>
                </Select>
              </FormControl>

              <InputLabel htmlFor="message" className={classes.label}>
                Would you like to add a comment ? <span className={classes.required}>*</span>
              </InputLabel>
              <TextField
                className={classes.input}
                id="message"
                placeholder="Type Your Message!"
                multiline
                rows={3}
                variant="outlined"
                color="secondary"
              />
              <TextField
                className={classes.input}
                id="name"
                placeholder="Your Name (Optional)"
                variant="outlined"
                color="secondary"
              />
              <TextField
                className={classes.input}
                id="email"
                placeholder="Email (Optional)"
                variant="outlined"
                color="secondary"
              />
              <TextField
                className={classes.input}
                id="phoneNumber"
                placeholder="Phone Number (Optional)"
                variant="outlined"
                color="secondary"
              />
            </form>

            <Button variant="outlined" size="large" color="secondary" className={classes.input}>
              Send Feedaback
            </Button>
          </CardContent>
        </Card>
      </div>
  );
}
