import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';

import { MenuItem, Menu, Button, createMuiTheme, ThemeProvider } from '@material-ui/core';

import s from './LanguageController.scss';

const useStyles = makeStyles(() => ({
  expandIcon: {
    marginLeft: 0,
  },
}));

const languageName = {
  en: 'English'
};
export const LanguageController = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
        },
      }),
    [],
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => {
            return (
              <>
                <Button
                  className={s.fontCon__menuContainer}
                  aria-controls="font-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  endIcon={<ExpandIcon className={classes.expandIcon} />}
                >
                  {languageName[currentLocale]}
                </Button>
                <Menu
                  elevation={0}
                  variant="menu"
                  id="font-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{
                    paper: s.fontCon__flat,
                  }}
                >
                  {languages.map((language) => (
                    <MenuItem
                      style={{ fontSize: 'inherit' }}
                      key={language}
                      selected={currentLocale === language}
                      classes={{
                        selected: s.fontCon__selected,
                      }}
                    >
                      <span style={{ minWidth: 20, marginRight: 5 }}>{languageName[language]}</span>
                      {currentLocale === language && <CheckIcon fontSize="small" />}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            );
          }}
        </IntlContextConsumer>
      </ThemeProvider>
    </>
  );
};
