import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Typography, Popover, MenuItem } from '@material-ui/core';
import h from './Headersearch.scss';
import logo from 'assets/images/ic-browseby.png'
import logo2 from 'assets/images/ic-Indicator.png'
import logo3 from 'assets/images/ic-sources.png'
import logo4 from 'assets/images/ic-states.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      height:30,
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 5,
    },
    divider: {
      height: 44,
      margin: 0,
      color: '#C6C6C6',
    },
   
      formControl: {
        minWidth: 120,
      },
      popover: {
        pointerEvents: 'none',
     
      },
      paper: {
        padding: theme.spacing(0.8),
        marginTop:'1.35rem',
        marginRight:'10rem',
        width:'15rem'
      },
  }),
);

 function HeaderSearch() {
  const classes = useStyles();


  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
  
<form className={h.homewrap__searchbar}>
       <InputBase
              placeholder="What are you looking for ?"
              inputProps={{ 'aria-label': 'search google maps' }}
              className={`${classes.input} ${h.homewrap__searchinput} `}
            />
        <Divider className={classes.divider} orientation="vertical" variant="fullWidth"/>
            <Button type="submit" className={h.homewrap___searchbtn}>
              <SearchIcon />
            </Button>

          </form>
          <img src={logo} className={h.homewrap__BrowsebyIcon}/>

          <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        Browse by
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 88, left: 715 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MenuItem value={'indicators'} className={h.homewrap__papermenuitem}><img src={logo2} className={h.homewrap__paperImg}/> Indicators</MenuItem>
         <Divider /> <MenuItem value={'sources'} className={h.homewrap__papermenuitem}><img src={logo3} className={h.homewrap__paperImg}/> Sources</MenuItem>
         <Divider /><MenuItem value={'states/Uts'} className={h.homewrap__papermenuitem}><img src={logo4} className={h.homewrap__paperImg}/> States/UTs</MenuItem>
      </Popover>
    </div>
      {/* <FormControl className={classes.formControl}>
        
        <InputLabel id="demo-controlled-open-select-label" > Browse by</InputLabel>
        <Select 
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onMouseEnter={handleOpen}
          value={browse}
          onChange={handleChange}
          disableUnderline={true}
          className={h.homewrap__browsebytext}
        >
        
          <MenuItem value={'indicators'}>Indicators</MenuItem>
          <MenuItem value={'sources'}>Sources</MenuItem>
          <MenuItem value={'states/Uts'}>States/UTs</MenuItem>
         
        </Select>
      </FormControl> */}
    
   </React.Fragment>
  );
}
export default HeaderSearch;
