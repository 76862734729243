import React, { ReactNode } from 'react';
// import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'gatsby-plugin-intl';
// import { Link } from 'components/link/Link';
import logo from 'assets/images/india-emblem.png'
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';

// import { changeLocale } from 'gatsby-plugin-intl'

import {
  AppBar,
  Typography,
  Toolbar,
  MenuItem,
  Menu,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
} from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
// import { Link } from 'components/link/Link';
// import StarterLogo from 'assets/svg/starter-logo.svg';

// import s from './Header.scss';
import { HeaderTop } from './HeaderTop';
import SignUp from './SignUp';

import Signin from 'components/Signin/Signin';
import HeaderSearch from './HeaderSearch';
import { useLocation } from '@reach/router';
import ProfileDetails from './profileDetails';
import s from './header.scss'
interface HeaderProps {
  siteTitle: string;
}

// function ElevationScroll(props: HeaderProps) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 0,
//   });
// }

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  actionButtons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  title: {
    flexGrow: 1,
  },
  divider: {
    height: 40,
    margin: 5,
    color: '#C6C6C6',
  },
}));

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export const Header = (props: HeaderProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const { siteTitle } = props;
  const { href, pathname } = useLocation();
  //  const url = location.href ? location.href : '';
  //   let urlParts = url.split('/')
  //   urlParts.map((item,index)=>{
  //       if(urlParts.length > 4){
  //           return true;
  //       }else if (urlParts.length === 4 && urlParts )
  //   })

  // const handleClick = (event:any)=>{
  //   // alert('clicked');
  //   changeLocale('en');
  // }

  const [open, setOpen] = React.useState(false);
  const [isSignin, setIsSignin] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getSignInHandler =()=>{
     setIsSignin(true)
  }

  return (
    <AppBar color={'default'}>
      <HeaderTop></HeaderTop>
      {/* <Button onClick={handleClick}>Click me</Button> */}
      <Toolbar>
      
        <Grid container direction="row" justify="center" alignItems="center" >
        <img src={logo} />
        <Divider className={classes.divider} orientation="vertical" variant="fullWidth"/>
          <Grid item md={2}>
           
            <Typography component="p" className={s.header__title}>
              {intl.formatMessage({ id: 'title' })}
            </Typography>
            <Typography component="span" className={s.header__subtitle1}>
              {intl.formatMessage({ id: 'subtitle1' })}
            </Typography>
            <Typography component="span" className={s.header__subtitle2}>
              {intl.formatMessage({ id: 'subtitle2' })}
            </Typography>
          </Grid>
          
         {pathname === '/'? null:<HeaderSearch/>} 
          
         
           
          <Grid
            md
            container
            alignItems="center"
            item
            justify="flex-end"
            direction="row"
            className={classes.actionButtons}
          >
          
            {isSignin? <ProfileDetails/>:
            <>
            <Signin  isSign={getSignInHandler}/>
            <SignUp />
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              open={false}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>My account</MenuItem>
              
            </Menu> </>}
            
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

// export default Header;
// export const Header = ({ children }: HeaderProps) => (
//   <header className={s.header}>
//     <div className={s.header__container}>
//       <div className={s.header__content}>
//         <Link to="/" className={s.header__logo}>
//           <StarterLogo className={s.header__logoSvg} />
//         </Link>

//         <div className={s.header__navigation}>{children}</div>
//       </div>
//     </div>
//   </header>
// );
