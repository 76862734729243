import React from 'react';
import { createStyles, Theme, withStyles, WithStyles,makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import s from 'components/Signin/Signin.scss'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, FormControlLabel, Checkbox, Container } from '@material-ui/core';
// import s from 'components/Signin/Signin.scss'
import TextField from '@material-ui/core/TextField'; 
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Link } from 'gatsby-plugin-intl';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import logo from 'assets/images/google.png'


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        margin: '0.5rem 0rem',
        width: '100%',
      },
      secondary :{
        color: '$color-hightlight-text',
      }
     
    },
    labelB: {
      fontWeight: 400,
      fontSize: 13,
      
    }
  }),
);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    secondary:{
      color:'$color-hightlight-text',
    },
   
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default function CustomizedDialogs(props:any) {
    const classes = useStyles();
    
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
       <Typography className={s.button}>Sign In</Typography>
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
       
      <Container maxWidth="xs" className={s.ContainText}>
         <DialogTitle id="customized-dialog-title" onClose={handleClose}>
       
          <Typography className={s.SignIn__text}>Sign In</Typography>
          <Typography className={s.SignIn__Caption}>with your social network</Typography>
      
          <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={4}><Button variant="outlined" size="small" className={s.SignIn__Googlebtn}>
          <img src={logo}  className={s.SignIn__GoogleImg}/> 
          Google</Button></Grid>
          <Grid item xs={4}> <Button variant="outlined"  size="small" className={s.SignIn__Facebookbtn}>
           <FacebookIcon className={s.SignIn__GoogleImg} /> Facebook
            </Button></Grid>
            <Grid item xs={4}> <Button variant="outlined"  size="small"  className={s.SignIn__Twitterbtn}>
            <TwitterIcon className={s.SignIn__GoogleImg}/> Twitter
            </Button></Grid>
            </Grid>
           <Typography className={s.SignIn__or}>or</Typography>
          
        </DialogTitle>
        <DialogContent>
        <Grid container direction="row" justify="center" alignItems="center">
  
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" label="Username or email" variant="outlined" color="secondary" size="small" type="text" fullWidth/>
            <TextField id="outlined-basic" label="Password" size="small" variant="outlined" color="secondary" type="password" fullWidth/>
            </form>
       
            
               
            <Grid item xs className={s.SignIn__LFloat}>
   
              <FormControlLabel
                    control={
             
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="checkedI" 
                  
                        />
                       
                    }
                    label="Keep me signed in"
                    classes={{label:classes.labelB}}
                />
                </Grid> 
                <Grid item xs className={s.SignIn__RFloat}> Forgot Password?</Grid>
            
                </Grid>
                <Button  size="medium" className={s.SignIn__SignInButton} fullWidth  onClick={props.isSign}>
          Sign in
        </Button>
        <Typography className={s.SignIn__Caption2}>
        Not a member ?<Link to="" > Sign Up</Link>
        </Typography>
        </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}