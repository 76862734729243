import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'gatsby-plugin-intl';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';

import { MenuItem, Menu, Button, createMuiTheme, ThemeProvider, ListItemIcon } from '@material-ui/core';

import s from './FontController.scss';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    marginLeft: 0,
  }
}));

const options = ['A +', 'A', 'A -'];

function getFontSizeDetails(fontSize: string) {
  const value = parseFloat(fontSize);
  const unit = fontSize.toString().replace(value.toString(), '');
  return {
    value,
    unit,
  };
}
export const FontController = (props: { baseSize: string }) => {
  const { baseSize } = props;
  const range = 4;
  const intl = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const $html: HTMLHtmlElement = document.querySelector('html') || new HTMLHtmlElement();

  const { value, unit } = getFontSizeDetails(baseSize);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, currentIndex: number) => {
    let newSize = value;
    if (currentIndex === 1) {
      newSize = 0;
    } else if (currentIndex === 0) {
      newSize = value + range;
    } else {
      newSize = value - range;
    }

    $html.style.fontSize = newSize ? `${newSize}${unit}` : '';
    setSelectedIndex(currentIndex);

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
        },
      }),
    [],
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          className={s.fontCon__menuContainer}
          aria-controls="font-menu"
          aria-haspopup="true"
          onMouseEnter={handleClick}
          endIcon={<ExpandIcon className={classes.expandIcon} />}
        >
          A<small>A</small>
        </Button>
        <Menu
            elevation={0}
          variant="menu"
          id="font-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: s.fontCon__flat
          }}
        >
          {options.map((option, index) => (
            <MenuItem
            style={{fontSize:'inherit'}}
              key={option}
              selected={index === selectedIndex}
              classes={{
                selected: s.fontCon__selected
              }}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
                <span style={{minWidth:20, marginRight:5}}>
                {option}
                </span>
              
              
            {index === selectedIndex && <CheckIcon fontSize="small" />}
         
            </MenuItem>
          ))}
        </Menu>
      </ThemeProvider>
    </>
  );
};

FontController.propTypes = {
  baseSize: PropTypes.string,
};

FontController.defaultProps = {
  baseSize: `16px`,
};
