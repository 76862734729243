import React, { ReactNode } from 'react';
import { Container, Grid } from '@material-ui/core';

import s from './Footer.scss';
import { Social } from '../social/Social';

import { Link } from 'components/link/Link';
import { useIntl } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';
import { FooterLinks } from './FooterLinks';
import { FooterLinksBottom } from './FooterLinks';
import countIcon from 'assets/images/ic-visitorscount.png';
interface Social {
  icon: ReactNode;
  to: string;
}

// interface FooterProps {
//   logo: ReactNode;
//   social: Social[];
// }

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 16,
    height: 16,
  },

}))
const footerLinks1 = [
  { name : 'about'},
  { name : 'tutorials'},
  { name : 'feedback'},
  { name : 'help'},
  { name : 'sitemap'},
  { name : 'contact'}
];
const footerLinks2 = [
  { name : 'privacy'},
  { name : 'terms'},
  { name : 'policy'},
  { name : 'disclaimer'}
];
export const Footer = () => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <Container className={s.footer} maxWidth={false}>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs container justify="flex-start">
          <FooterLinks links={footerLinks1} />
        </Grid>
        <Grid item xs container justify="flex-end">
          <Social className="social-color"></Social>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs container justify="flex-start">
          <div className={s.footer__text}>
            <span>&copy; 2020 <span className={s.footer__text__highlight}>NDAP India.</span> All rights reserved</span>
          </div>
        </Grid>
        <Grid item xs container justify="flex-start">
          <FooterLinksBottom links={footerLinks2} />
          </Grid>
        <Grid item xs container justify="flex-end">
         <div className={s.footer__text}>
         Visitors count <img className={s.visitor} src={countIcon} alt="Logo" /> 25466
          </div>
        </Grid>
      </Grid>
    </Container>
  )
};
