import React, { ReactElement } from 'react';
import Container from '@material-ui/core/Container';
import axios from 'axios';

import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Devtools } from '../devtools/Devtools';
import { useStaticQuery, graphql } from 'gatsby'

import s from './AppLayout.scss';
import SEO from 'components/seo';
import { ThemeProvider, createMuiTheme, useMediaQuery, NoSsr } from '@material-ui/core';
import Feedback from '../feedback/Feedback';

interface AppLayoutProps {
  children: ReactElement;
}

const isDev = process.env.NODE_ENV === 'development';


// tslint:disable no-default-export
export default ({ children }: AppLayoutProps) => {

  axios.get('https://jsonplaceholder.typicode.com/posts')
  .then(function (response) {
    // handle success
    console.log(response);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
  
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'light'
        },
      }),
    [prefersDarkMode],
  );

  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (<div className={s.layout}>
    <ThemeProvider theme={theme}>
    <SEO title={data.site.siteMetadata.title}/>
    
    <Header siteTitle={data.site.siteMetadata.title}></Header>
    <Container className={s.containerspacing}>{children}</Container>
    <Footer/>
    <NoSsr defer={true}>
    <Feedback />
    </NoSsr>
    </ThemeProvider>  
    {isDev && <Devtools />}
  </div>)
};
