import React, { ReactNode } from 'react';
import { List, ListItem, Typography, IconButton, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import Twitter from '@material-ui/icons/Twitter';
import Facebook from '@material-ui/icons/Facebook';
import YouTube from '@material-ui/icons/YouTube';

import s from './Social.scss';
import { useIntl } from 'gatsby-plugin-intl';

interface Social {
  icon: ReactNode;
  to: string;
}

// interface FooterProps {
//   logo: ReactNode;
//   social: Social[];
// }

const SocialIcon = () => {
  return (
    <IconButton aria-label="cart" size="small" className={s.__circle}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export const Social = ({className}: {className: string}) => {
  const intl = useIntl();
  const social = [
    { icon: <Facebook fontSize="inherit" />, to: 'https://www.facebook.com/ndap', key:'facebook' },
    { icon: <Twitter fontSize="inherit" />, to: 'https://twitter.com/ndap', key:'twitter' },
    { icon: <YouTube fontSize="inherit" /> ,to: 'https://youtube.com/ndap', key:'youtube' }
  ];

  return (
    <div className={`${s.social} ${className}`}>
      <span className={s.social__title}>{intl.formatMessage({ id: 'followUs' })}</span>
      <List dense={true} className={s.social__list}>
        {social.map((item) => (
          <ListItem key={item.key} button={true} disableGutters={true}>
            <Link className={`${s.__circle} bg-${item.key}`} href={item.to} target="_blank" rel="noopener noreferrer">
              {item.icon}
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
