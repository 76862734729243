import { createStore, StoreEnhancer, compose } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';


function reducer(state: any, action: any) {
    // console.log('state', state, action);
  //...
}

// preloadedState will be passed in by the plugin
export default (preloadedState:any) => {
  return createStore(reducer, preloadedState, composeWithDevTools());
};