import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import s from 'components/Signin/Signin.scss'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import logo from 'assets/images/google.png'
import { Link } from 'gatsby-plugin-intl';
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Container,
  Grid,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  
  });
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      //margin: theme.spacing(1),
      minWidth: '100%',
      color:'$color-hightlight-text',
    },
    labelB: {
      fontWeight: 400,
      fontSize: 13,
      marginTop:'1rem',
      textAlign:"left"
      
    }
  }),
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme: Theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

function SignUp() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [userType, setUserType] = React.useState('');
  const [isCheck, setIsCheck] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event: any) => {
    setUserType(event.target.value);
  };
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheck(event.target.checked);
  };

  return (
    <div>
      <Button size="small" variant="outlined" className={s.SignIn__Registerbtn}  onClick={handleClickOpen}>
        <Typography>Register</Typography>
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <Container  className={s.ContainSignup}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}> 
        
        <Typography className={s.SignIn__text}>Sign up</Typography>
        <Typography className={s.SignIn__Caption}>with your social network</Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={4}><Button variant="outlined" size="small" className={s.SignIn__Googlebtn}>
          <img src={logo}  className={s.SignIn__GoogleImg}/> Google</Button></Grid>
          <Grid item xs={4}> <Button variant="outlined"  size="small" className={s.SignIn__Facebookbtn}>
           <FacebookIcon className={s.SignIn__GoogleImg} /> Facebook
            </Button></Grid>
            <Grid item xs={4}> <Button variant="outlined"  size="small"  className={s.SignIn__Twitterbtn}>
            <TwitterIcon className={s.SignIn__GoogleImg}/> Twitter
            </Button></Grid>
            </Grid>
           <Typography className={s.SignIn__or}>Or</Typography>
           </DialogTitle>
          <DialogContent >
            <FormControl variant="outlined" className={classes.formControl} size="small" color="secondary">
              <InputLabel htmlFor="select User Type">Select User Type</InputLabel>
              <Select native value={userType} onChange={handleChange} label="Age" fullWidth>
                <option aria-label="None" value="" />
                <option value={1}>Researcher</option>
                <option value={2}>Policy Maker</option>
                <option value={3}>Data Scientist</option>
                <option value={4}>General User</option>
                <option value={5}>Corporate</option>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Username"
              type="text"
              variant="outlined"
              color="secondary"
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              color="secondary"
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Create Password"
              type="password"
              variant="outlined"
              color="secondary"
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCheck}
                  onChange={handleCheck}
                  name="checkedB"
                  color="primary"
                />
              }
              label="I do not wish to receive news and updates from NDAP office by email"
             
              classes={{label:classes.labelB}}
            />

            <Button size="medium" className={s.SignIn__SignInButton} fullWidth onClick={handleClose} >
              SignUp
            </Button>
            <Typography className={s.SignIn__Caption2}>
              Already registered? <Link to="#">Sign in</Link>
            </Typography>
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}
export default SignUp;
